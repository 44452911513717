<template>
  <div :class="'slide-element' + (activeSlide ? '' : ' background-slide')">
    <div class="img-wrapper">
      <img
        :src="slide.src"
        :alt="slide.altText"
        class="img-current rounded-lg"
        style="width: 100%; height: 100%"
      />
    </div>
    <div class="text-container">
      <div class="img-title text-h2 pt-2 pb-4">{{ slide.title }}</div>
      <div class="img-text" v-html="slide.text"></div>
    </div>
    <navigation-button
      class="mt-4 mt-lg-8 ml-auto nav-button"
      v-bind="getButtonProps(slide)"
      :tabindex="!activeSlide ? -1 : 0"
    >
      {{ slide.buttonText }}
    </navigation-button>
    <slot />
  </div>
</template>

<script setup lang="ts">
interface Slide {
  src: string;
  altText: string;
  title: string;
  text: string;
  buttonText: string;
}

interface Props {
  slide: Slide;
  getButtonProps: (slide: Slide) => Record<string, any>;
  activeSlide?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  activeSlide: true,
});
</script>

<style lang="scss">
@use "~/../_common/assets/scss/mixins";

.slide-element {
  background: white;

  .img-wrapper {
    height: 260px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 80px;
      background: linear-gradient(
        to top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.4) 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  .text-container {
    @include mixins.media-query("lg-and-up") {
      text-align: right;
    }
  }

  .nav-button {
    position: absolute;
    bottom: 40px;
    right: 0;
  }

  &.background-slide {
    z-index: -1;
  }
}
</style>
